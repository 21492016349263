// import constants
import {
    CHARACTERS, MESSAGE_OK, STATUS_OK, FILTER_ALL, FACTORS_AVAILABLE, USER_KEY, USER_AGENCY, USER_BILLER,
    USER_MASTER, USER_SUPERVISOR, USER_ROOT, VEHICLE_BIKE, VEHICLE_CAR, VEHICLE_TRICYCLE, ORDERS_PENDING,
    ORDERS_STARTED, ORDERS_COMPLETED, ORDERS_ASSIGNED, ORDERS_REPORTED, NATURES, ISSUES_LOW, ISSUES_MEDIUM,
    ISSUES_HIGH, EVENTS_TREATMENT, EVENTS_INCIDENT, EVENTS_RTS, EVENTS_FORWARD, NATURES_DOCUMENT,
    NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS, INVOICES_PAID, INVOICES_UNPAID, USER_FACTOR,
    ORDERS_UNPAYED
} from "./constants"

// import error
import { ERROR_401 } from "./erreurs"

// import moment
import moment from 'moment'
// import 'moment/locale/fr'

/**
 * Convert dates on  timestamps.
 * 
 * @param {*} start 
 * @param {*} end 
 * @returns 
 */
export const convertDatesOnTimestamps = (start, end) => {
    return {
        start: moment(`${start.format('DD/MM/YYYY')} 00:00:00`, "DD/MM/YYYY HH:mm:ss").unix(),
        end: moment(`${end.format('DD/MM/YYYY')} 23:59:59`, "DD/MM/YYYY HH:mm:ss").unix()
    }
}

/**
 * Format time to string.
 * 
 * @param {*} time 
 * @returns 
 */
export const formatTimeToString = (time) => {
    try {
        return ((time < 10) ? `0${time}` : `${time}`)
    } catch (error) {
        console.log(error)
        return (`${time}`)
    }
}


/**
 * Format exported filename.
 * 
 * @param {*} name 
 * @param {*} from 
 * @param {*} to 
 * @param {*} option 
 * @returns 
 */
export const formatExportedFilename = (name, from, to, option = "") => {
    try {
        return (`${name}${(option !== "") ? `_${option}` : ""}${(from !== "") ? `_du_${from}` : ""}${((to !== "") && (to !== from)) ? `_au_${to}` : ""}`)
    } catch (error) {
        console.log(error)
    }
}

/**
 * Capitalize first letter of param.
 * 
 * @param {*} string 
 * @returns string
 */
export const upperFirst = (value) => {
    return (value.charAt(0).toUpperCase() + value.slice(1).toLowerCase())
}

/**
 * Check if [value] is not an empty string.
 * 
 * @param {*} value 
 * @returns 
 */
export const isNotEmptyOrBlank = (value) => {
    return ((typeof (value) === "string") && (value.trim().length > 0))
}

/**
 * Check if [value] is not an empty string.
 * 
 * @param {*} value 
 * @returns 
 */
export const isNotNullNumber = (value) => {
    return ((typeof (value) === "number") && (value > 0))
}

/**
 * Parse integer
 * 
 * @param {*} value 
 * @returns 
 */
export const parseNumber = (value) => {
    const parsed = parseInt(value, 10)
    return (isNaN(parsed) ? 0 : parsed)
}

/**
 * Get items on [arrayFrom] but not on [arrayTo] based on [keys].
 * 
 * @param {*} arrayFrom 
 * @param {*} arrayTo 
 * @param {*} fromKey 
 * @param {*} toKey 
 * @returns array
 */
export const filterArraybyArray = (arrayFrom, arrayTo, fromKey = "_id", toKey = "_id") => {
    return arrayFrom.filter((afx) => !arrayTo.some((atx) => (afx[fromKey] === atx[toKey])))
}

/**
 * Check if request is successful.
 * 
 * @param {*} status 
 * @param {*} statusText 
 * @returns bool
 */
export const isSuccessfulRequest = (status, statusText) => {
    return (((status === STATUS_OK) && (statusText.toLowerCase() === MESSAGE_OK)) || (status === STATUS_OK))
}

/**
 * Check if request is unauthorized.
 * 
 * @param {*} status 
 * @param {*} statusText 
 * @returns bool
 */
export const isUnauthorizedRequest = (status, statusText) => {
    return ((status === ERROR_401.code) && (statusText.toLowerCase() === ERROR_401.label.toLowerCase()))
}

/**
 * Get user role [attribut] with [key] or
 * Get factor vehicle [attribut] with [key] or
 * Get factor status [attribut] with [key] or
 * Get order status [attribut] with [key] or
 * 
 * @param {*} key 
 * @param {*} attribut 
 * @returns any
 */
export const getConstantObject = (key, attribut = "") => {
    switch (key) {
        case USER_ROOT.key:
            return ((attribut !== "") ? USER_ROOT[attribut] : USER_ROOT)
        case USER_MASTER.key:
            return ((attribut !== "") ? USER_MASTER[attribut] : USER_MASTER)
        case USER_AGENCY.key:
            return ((attribut !== "") ? USER_AGENCY[attribut] : USER_AGENCY)
        case USER_FACTOR.key:
            return ((attribut !== "") ? USER_FACTOR[attribut] : USER_FACTOR)
        case USER_BILLER.key:
            return ((attribut !== "") ? USER_BILLER[attribut] : USER_BILLER)
        case USER_SUPERVISOR.key:
            return ((attribut !== "") ? USER_SUPERVISOR[attribut] : USER_SUPERVISOR)
        case VEHICLE_BIKE.key:
            return ((attribut !== "") ? VEHICLE_BIKE[attribut] : VEHICLE_BIKE)
        case VEHICLE_CAR.key:
            return ((attribut !== "") ? VEHICLE_CAR[attribut] : VEHICLE_CAR)
        case VEHICLE_TRICYCLE.key:
            return ((attribut !== "") ? VEHICLE_TRICYCLE[attribut] : VEHICLE_TRICYCLE)
        case ORDERS_PENDING.key:
            return ((attribut !== "") ? ORDERS_PENDING[attribut] : ORDERS_PENDING)
        case ORDERS_ASSIGNED.key:
            return ((attribut !== "") ? ORDERS_ASSIGNED[attribut] : ORDERS_ASSIGNED)
        case ORDERS_STARTED.key:
            return ((attribut !== "") ? ORDERS_STARTED[attribut] : ORDERS_STARTED)
        case ORDERS_COMPLETED.key:
            return ((attribut !== "") ? ORDERS_COMPLETED[attribut] : ORDERS_COMPLETED)
        case ORDERS_REPORTED.key:
            return ((attribut !== "") ? ORDERS_REPORTED[attribut] : ORDERS_REPORTED)
        case ISSUES_LOW.key:
            return ((attribut !== "") ? ISSUES_LOW[attribut] : ISSUES_LOW)
        case ISSUES_MEDIUM.key:
            return ((attribut !== "") ? ISSUES_MEDIUM[attribut] : ISSUES_MEDIUM)
        case ISSUES_HIGH.key:
            return ((attribut !== "") ? ISSUES_HIGH[attribut] : ISSUES_HIGH)
        case EVENTS_TREATMENT.key:
            return ((attribut !== "") ? EVENTS_TREATMENT[attribut] : EVENTS_TREATMENT)
        case EVENTS_INCIDENT.key:
            return ((attribut !== "") ? EVENTS_INCIDENT[attribut] : EVENTS_INCIDENT)
        case EVENTS_RTS.key:
            return ((attribut !== "") ? EVENTS_RTS[attribut] : EVENTS_RTS)
        case EVENTS_FORWARD.key:
            return ((attribut !== "") ? EVENTS_FORWARD[attribut] : EVENTS_FORWARD)
        case NATURES_DOCUMENT.key:
            return ((attribut !== "") ? NATURES_DOCUMENT[attribut] : NATURES_DOCUMENT)
        case NATURES_ENCOMBRANT.key:
            return ((attribut !== "") ? NATURES_ENCOMBRANT[attribut] : NATURES_ENCOMBRANT)
        case NATURES_FRAGILE.key:
            return ((attribut !== "") ? NATURES_FRAGILE[attribut] : NATURES_FRAGILE)
        case NATURES_FRAIS.key:
            return ((attribut !== "") ? NATURES_FRAIS[attribut] : NATURES_FRAIS)
        case INVOICES_PAID.key:
            return ((attribut !== "") ? INVOICES_PAID[attribut] : INVOICES_PAID)
        case INVOICES_UNPAID.key:
            return ((attribut !== "") ? INVOICES_UNPAID[attribut] : INVOICES_UNPAID)
        default:
            return ""
    }
}

/**
 * 
 * @param {*} size 
 * @param {*} withUpper 
 * @returns string
 */
export const generateRandomCode = (size = 6, withUpper = false) => {
    if (withUpper) {
        let result = "";
        let charactersLength = CHARACTERS.length;
        for (let i = 0; i < size; i++) {
            result += CHARACTERS.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    } else {
        return Math.random().toString(20).substr(2, size)
    }
}

/**
 * Format phone number
 * 
 * @param {*} value 
 * @returns string
 */
export const formatPhoneNumberForRequest = (value) => {
    try {
        if ((value.length === 11) || (value.length === 0)) return value
        if (value.length === 8) return (`229${value}`)
        let phoneData = value.split(" ")
        return (`229${phoneData[1]}${phoneData[2]}${phoneData[3]}${phoneData[4]}`)
    } catch (error) {
        console.log(error)
        return ""
    }
}

/**
 * Format phone for display.
 * 
 * @param {*} value 
 * @returns string
 */
export const formatPhoneNumberForDisplay = (value) => {
    try {
        if ((value.length === 18) || (value.length === 0)) return value
        let phone1 = value.substr(((value.length == 8) ? 0 : 3), 2)
        let phone2 = value.substr(((value.length == 8) ? 2 : 5), 2)
        let phone3 = value.substr(((value.length == 8) ? 4 : 7), 2)
        let phone4 = value.substr(((value.length == 8) ? 6 : 9), 2)
        return (`(+229) ${phone1} ${phone2} ${phone3} ${phone4}`)
    } catch (error) {
        console.log(error)
        return ""
    }
}

/**
 * Filter [list] by [attribut] based on [attributKey]
 * if [count] return list's size otherwise return list
 * 
 * @param {*} list
 * @param {*} attribut 
 * @param {*} attributKey 
 * @returns array
 */
export const filterListByAttribut = (list, attribut, count = false, attributKey = "status") => {
    try {
        let results = list.filter((item) => (
            item[attributKey] && (item[attributKey] === attribut)
        ))
        return (count ? results.length : results)
    } catch (error) {
        console.log(error)
        return (count ? 0 : [])
    }
}

/**
 * Filter [list] by [option] and [attribut] based on [optionKey] and [attributKey].
 * if [count] return list's size otherwise return list.
 * 
 * @param {*} list 
 * @param {*} option 
 * @param {*} attribut 
 * @param {*} count 
 * @param {*} optionKey 
 * @param {*} attributKey 
 * @returns 
 */
export const filterListByOptionAndAttribut = (list, option, attribut, count = true, optionKey = "factor_id", attributKey = "status") => {
    try {
        let results = []
        if (option === FILTER_ALL.key) {
            results = list.filter((item) => (
                item[optionKey] && (item[optionKey] === option)
            ))
        } else {
            results = list.filter((item) => (
                item[optionKey] && (item[optionKey] === option) &&
                item[attributKey] && (item[attributKey] === attribut)
            ))
        }
        return (returnList ? results : results.length)
    } catch (error) {
        console.log(error)
        return (returnList ? [] : 0)
    }
}

/**
 * Format User With Name.
 * 
 * @param {*} user 
 * @returns 
 */
export const formatUserWithName = (user) => {
    try {
        return {
            ...user,
            name: user.name ? user.name : (user.firstname && user.lastname) ? `${user.firstname} ${user.lastname}` : ""
        }
    } catch (error) {
        console.log(error)
        return {}
    }
}

/**
 * Format order nature.
 * 
 * @param {*} orderNatures 
 * @returns 
 */
export const formatOrderNature = (orderNatures) => {
    try {
        let natures = [NATURES_DOCUMENT, NATURES_ENCOMBRANT, NATURES_FRAGILE, NATURES_FRAIS]
        return natures.filter((item) => orderNatures.includes(item.key)).reduce(
            (previousValue, currentValue) => (`${(previousValue === "") ? "" : `${previousValue} - `}${currentValue.name}`), ""
        )
    } catch (error) {
        console.log(error)
        return []
    }
}

/**
 * Format orders with status.
 * 
 * @param {*} orders 
 * @returns 
 */
export const formatOrdersWithStatus = (orders) => {
    try {
        return orders.map((item) => ({
            ...item, status: getConstantObject(item.current_status)
        }))
    } catch (error) {
        console.log(error)
        return []
    }
}

/**
 * Format factor with agencies.
 * 
 * @param {*} factors 
 * @param {*} agencies 
 * @param {*} factorKey 
 * @param {*} agencyKey 
 * @returns 
 */
export const formatFactorsWithAgency = (factors, agencies, factorKey = "agency_id", agencyKey = "_id") => {
    try {
        return factors.map((item) => {
            let aFactor = agencies.find((aItem) => (aItem[agencyKey] === item[factorKey]))
            return { ...item, agency: aFactor }
        }).reverse()
    } catch (error) {
        console.log(error)
        return []
    }
}

/**
 * Format issues with gravity.
 *  
 * @param {*} issues 
 * @returns 
 */
export const formatIssuesWithGravity = (issues) => {
    try {
        return issues.map((item) => ({
            ...item, gravity: getConstantObject(item.severity)
        }))
    } catch (error) {
        console.log(error)
        return []
    }
}

/**
 * Format events with type.
 * 
 * @param {*} events 
 * @returns 
 */
export const formatEventsWithType = (events) => {
    try {
        return events.map((item) => ({
            ...item, type: getConstantObject(item.event)
        }))
    } catch (error) {
        console.log(error)
        return []
    }
}

/**
 * Filter [list] by [attribut] based on [attributKey]
 * if [count] return list's size otherwise return list
 * 
 * @param {*} list
 * @param {*} attribut 
 * @param {*} attributKey 
 * @returns array
 */
export const filterOrdersByStatus = (list, attribut, count = false, attributKey = "current_status") => {
    try {
        let results = list.filter((item) => {
            if (attribut === ORDERS_ASSIGNED.key) {
                return (
                    item[attributKey] && (item[attributKey] === attribut) && (item.current_user_id !== "")
                )
            } else if (attribut === ORDERS_REPORTED.key) {
                return (
                    item[attributKey] && (item[attributKey] === ORDERS_STARTED.key) && item.reported
                )
            } else if (attribut === ORDERS_UNPAYED.key) {
                return (
                    item[attributKey]?.trim() === ""
                )
            } else {
                return (
                    item[attributKey] && (item[attributKey] === attribut)
                )
            }
        })

        return (count ? results.length : results)
    } catch (error) {
        console.log(error)
        return (count ? 0 : [])
    }
}

/**
 * Filter [list] by [attribut] based on [attributKey]
 * if [count] return list's size otherwise return list
 * 
 * @param {*} list
 * @param {*} attribut 
 * @param {*} attributKey 
 * @returns array
 */
export const filterInvoicesByStatus = (list, attribut, count = false, attributKey = "bill_status") => {
    try {
        let results = list.filter((item) => (
            item[attributKey] && (item[attributKey] === attribut)
        ))
        return (count ? results.length : results)
    } catch (error) {
        console.log(error)
        return (count ? 0 : [])
    }
}

/**
 * Make Human Readable.
 * 
 * @param {*} num 
 * @param {*} singular 
 * @returns 
 */
export const makeHumanReadable = (num, singular) => {
    return ((num > 0) ? (`${num}${(num > 1) ? ` ${singular}s, ` : ` ${singular}, `}`) : "");
}

/**
 * Convert to days minutes seconds from [value].
 * 
 * @param {*} value 
 * @param {*} formated 
 * @returns 
 */
export const toDaysMinutesSeconds = (value, formated = false) => {
    try {
        value = Number(value);
        let seconds = Math.floor(value % 60);
        let minutes = Math.floor((value % 3600) / 60);
        let hours = Math.floor((value % (3600 * 24)) / 3600);
        let days = Math.floor(value / (3600 * 24));

        let daysStr = ""; let hoursStr = ""; let minutesStr = ""; let secondsStr = "";

        if (formated) {
            secondsStr = makeHumanReadable(seconds, 'seconde');
            minutesStr = makeHumanReadable(minutes, 'minute');
            hoursStr = makeHumanReadable(hours, 'heure');
            daysStr = makeHumanReadable(days, 'jour');
        } else {
            daysStr = ((days > 0) ? `${days}j` : "");
            hoursStr = ((hours > 0) ? ` ${hours}h` : "");
            minutesStr = ((minutes > 0) ? ` ${minutes}m` : "");
            secondsStr = ((seconds > 0) ? ` ${seconds}s` : "");
        }

        return `${daysStr}${hoursStr}${minutesStr}${secondsStr}`.replace(/,\s*$/, '');
    } catch (error) {
        console.log(error)
        return ""
    }
}

/**
 * Format Agency Name.
 * 
 * @param {*} name 
 * @returns 
 */
export const formatAgencyName = (name) => {
    try {
        return (name.replace((/ /gi), "_"))
    } catch (error) {
        console.log(error)
    }
}