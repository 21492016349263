import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import router
import { Link, withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import redux, actions
import { connect } from 'react-redux'
import {
    filterInvoices, resetInvoices, searchInvoices, setInvoices, showCustomer
} from 'store/actions'

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import custom components
import SearchInvoiceForm from './SearchInvoiceForm'

// import custom invoices components
import InvoiceDetails from 'pages/invoices/InvoiceDetails'
import InvoiceList from 'pages/invoices/InvoiceList'

// import constants
import { APP_NAME, PARTNERS_SHOW, INVOICES_SEARCH } from 'helpers/constants'

// import utilities
import { convertDatesOnTimestamps, formatPhoneNumberForRequest } from 'helpers/utilities'


class SearchFactures extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            query: {
                year: moment().subtract(1, 'month').startOf('month').year(),
                month: (moment().subtract(1, 'month').startOf('month').month() + 1)
            },
            detailsVisible: false
        }
    }

    componentDidMount() {
        try {// Load searches
            // console.log("SEARCH ORDERS MOUNTED")
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {// Check state updates
            // console.log(this.props, "SEARCH ORDERS UPDATED")
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetInvoices(true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            // Get target
            let { checked, value, name, type } = event.target

            // Save values
            this.setState({
                query: {
                    ...this.state.query,
                    [name]: ((type === "checkbox") ? checked : value)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
    * On submited.
    * 
    * @param {*} e 
    * @param {*} values 
    */
    onFound = (option) => {
        try {//Start treatment
            let { onSearchInvoices } = this.props
            let { month, year, code } = this.state.query

            let reference = (option === "code") ? code : ""
            let month_period = (option === "period") ? month : ""
            let year_period = (option === "period") ? year : ""

            onSearchInvoices(reference, month_period, year_period, INVOICES_SEARCH)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On date ranged.
     * 
     * @param {*} start 
     * @param {*} end 
     */
    onPicked = (year, month, option = "") => {
        try {
            //Save values
            this.setState({
                query: {
                    ...this.state.query,
                    year: year, month: month
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On showed.
     * 
     * @param {*} selected 
     */
    onShowed = (id, option) => {
        try {
            let { invoices, onResetInvoices, onSetInvoices, onShowCustomer } = this.props

            onResetInvoices(false)

            let selected = invoices.filter((item) => (item._id === id))

            onSetInvoices({ invoice: { ...selected[0] }, option: option })

            if (option === PARTNERS_SHOW) {
                onShowCustomer(selected[0].customer_id, option, { invoice: selected[0] })
            }

            //Show modal
            this.onToggled("detailsVisible", true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
    * On searched.
    *  
    * @param {*} list 
    * @returns 
    */
    onSearched = (searchedList) => {
        try {
            //Get props
            let { filter, onSetInvoices, onFilterInvoices } = this.props

            //Reset filters
            if (filter && filter.key) onFilterInvoices(filter, true)

            //Search orders
            onSetInvoices({ filtereds: searchedList })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On filtered.
     * 
     * @param {*} selected 
     */
    onFiltered = (selected) => {
        try {
            //Get props
            let { filter, onFilterInvoices, onResetInvoices, onSetInvoices } = this.props

            //Reset state
            onResetInvoices(false)

            //Save filter
            onFilterInvoices(selected, (selected.key === this.props.filter.key))
        } catch (error) {
            console.log(error)
        }
    }


    render() {
        const { t, alls, invoices, invoice, filter, loading, customer_loading, success, error, option } = this.props
        const { detailsVisible, query } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Rechercher une facture")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Rechercher une facture")} />

                        <Row className="mb-2">
                            <Col xl="3" sm="12" className="pe-3 pe-xxl-4">
                                <SearchInvoiceForm
                                    onSearched={this.onFound}
                                    onChanged={this.onChanged}
                                    onPicked={this.onPicked}
                                    query={query}
                                />
                            </Col>

                            <Col xl="9" sm="12" className="ps-3 ps-xxl-4">
                                <Row>
                                    {((error != "") && [INVOICES_SEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Alert color="danger" className="m-0">
                                                {error}
                                            </Alert>
                                        </Col>
                                    ) : (loading && [INVOICES_SEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Loader color="primary" />
                                        </Col>
                                    ) : (
                                        <InvoiceList
                                            alls={alls}
                                            items={invoices}
                                            filter={filter}
                                            onShowed={this.onShowed}
                                            onSearched={this.onSearched}
                                            onFiltered={this.onFiltered}
                                            showRefresh={false}
                                            totalPerPage={10}
                                            itemCol={4}
                                        />
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(detailsVisible) ? (
                    <InvoiceDetails
                        opened={detailsVisible}
                        modalOption={option}
                        modalError={error}
                        modalLoading={(option === PARTNERS_SHOW) ? customer_loading : loading}
                        onClosed={this.onToggled}
                        invoice={invoice}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
SearchFactures.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    invoices: PropTypes.array,
    invoice: PropTypes.object,
    onListInvoices: PropTypes.func,
    onSetInvoices: PropTypes.func,
    onResetInvoices: PropTypes.func,
}
const mapStateToProps = state => ({
    alls: state.factures.invoices,
    invoices: state.factures.filtereds,
    invoice: state.factures.invoice,
    filter: state.factures.filter,
    error: state.factures.error,
    option: state.factures.option,
    success: state.factures.success,
    loading: state.factures.loading,
    customer_loading: state.clients.loading
})
const mapDispatchToProps = dispatch => ({
    onSetInvoices: (data) => dispatch(setInvoices(data)),
    onResetInvoices: (all) => dispatch(resetInvoices(all)),
    onFilterInvoices: (filter, reset) => dispatch(filterInvoices(filter, reset)),
    onSearchInvoices: (reference, month, year, option) => dispatch(searchInvoices(reference, month, year, option)),
    onShowCustomer: (id, option, extra) => dispatch(showCustomer(id, option, extra))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SearchFactures)))
