import React from 'react'

import PropTypes from 'prop-types'

// import components
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Alert } from "reactstrap"

// import toaster
import { toast } from 'react-toastify'

// import form components
import { AvForm, AvField } from "availity-reactstrap-validation"

// import store and actions
import { connect } from 'react-redux'
import { payOrder, resetOrders, setOrders } from 'store/actions'

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import deep-equal
import equal from "deep-equal"

// Helpers
import { MIN_TEXT_INPUT, ORDERS_PAYED } from 'helpers/constants'
import { formatPhoneNumberForDisplay } from 'helpers/utilities'
 
// Components
import Loader from 'components/common/Loader'

class OrderPaid extends React.Component {

    constructor(props) {
        super(props)

        this.state = {}
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { success, option, onClosed } = this.props

                if (success) {
                    if (option === ORDERS_PAYED) {
                        // Display toasts
                        toast.success("Statut de la course, mis à jour avec succès.")

                        //Close modal
                        onClosed("payedVisible", false)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(false)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On Submited.
     * 
     * @param {*} e 
     * @param {*} values 
     */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {
            // Get props values
            let { option, order, onPayOrder } = this.props
            
            // Launch request
            onPayOrder({
                id: order._id,
                code: order.code,
                data: {
                    transaction_id: values.transaction_id.trim(),
                    order_code: order.code
                }
            }, option)
        } catch (error) {
            console.error(error)
        }
    }

    render() {
        const { opened, option, classes, error, loading, success, onClosed, order } = this.props
        const { code, created_at, payment, nature, customer } = order

        return (
            <Modal isOpen={opened} centered={true} className={classes}>
                    <ModalHeader tag="h4">
                        Notifier qu'une commande est payée
                        {(created_at && code) ? (
                            <span className="d-block fs-12 text-secondary">
                                Course {" "}
                                <strong> #{code} </strong>
                                du {" "}
                                <strong>
                                    {`${moment.unix(created_at).format('DD MMM YYYY HH:mm')}`}
                                </strong>
                            </span>
                        ) : null}
                    </ModalHeader>
                <AvForm onValidSubmit={this.onSubmited}>
                    <ModalBody>
                        {(order && order._id) && (
                            <Row>
                                {(error && (error !== "") && (option === ORDERS_PAYED)) ? (
                                        <Col xl="12">
                                            <Alert color="danger">{error}</Alert>
                                        </Col>
                                ) : null}

                                    <Col xl="12" className="mb-2">
                                        <div className="d-flex align-items-center justify-content-between mb-2">
                                            <span>
                                                <small className="text-muted text-decoration-underline me-1">Coût</small>
                                                <strong> {payment.price} </strong>(XOF)
                                            </span>
                                            <span>
                                                <small className="text-muted text-decoration-underline ms-1">Nature</small>
                                                <strong> {nature} </strong>
                                            </span>
                                        </div>
                                    </Col>

                                    {(customer) && (
                                        <Col xl="12" className="mb-3">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <span>
                                                    <small className="text-muted text-decoration-underline me-1">Nom du client</small>
                                                    <strong> {customer.name} </strong>
                                                </span>
                                                <span>
                                                    <small className="text-muted text-decoration-underline ms-1">Contact du client</small>
                                                    <strong> {formatPhoneNumberForDisplay(customer.phone)} </strong>
                                                </span>
                                            </div>
                                        </Col>
                                    )}

                                    <Col xl="12" className="mb-3">
                                        <AvField
                                            required
                                            name="transaction_id"
                                            label="Identifiant de la transaction"
                                            type="text"
                                            validate={{
                                                minLength: {
                                                    value: MIN_TEXT_INPUT,
                                                    errorMessage: (`L'identifiant de la transaction a au moins ${MIN_TEXT_INPUT} caractères.`)
                                                }
                                            }}
                                            helpMessage="Veuillez demander au client de vous fournir l'identifiant de la transaction du paiement de sa course, reçu de kkiapay via mail et/ou sms."
                                        />
                                    </Col>
                            </Row>
                        )}
                    </ModalBody>
                    <ModalFooter>
                            {(loading) ? (
                                <Loader color="primary" />
                            ) : (
                                <>
                                    <button
                                        type="button"
                                        data-dismiss="modal"
                                        className="btn btn-light"
                                        onClick={() => onClosed("payedVisible", false)}
                                    >
                                        Fermer
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary save-paid"
                                    >
                                        Enregistrer
                                    </button>
                                </>
                            )}
                        </ModalFooter>
                </AvForm>
            </Modal>
        )
    }
}
OrderPaid.propTypes = {
    opened: PropTypes.bool,
    classes: PropTypes.string,
    option: PropTypes.string,
    error: PropTypes.string,
    loading: PropTypes.bool,
    onClosed: PropTypes.func.isRequired,
    order: PropTypes.object,
}
OrderPaid.defaultProps = {
    opened: false,
    classes: "order-paid-form-modal"
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onPayOrder: (data, option) => dispatch(payOrder(data, option))
})
export default connect(mapStateToProps, mapDispatchToProps)(OrderPaid)