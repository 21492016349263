import React from 'react'
import PropTypes from 'prop-types'

// import helmet for meta
import { Helmet } from "react-helmet"

// import router
import { withRouter } from "react-router-dom"

// import i18n
import { withTranslation } from "react-i18next"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import redux, actions
import { connect } from 'react-redux'
import { filterOrders, resetOrders, searchOrders, setOrders } from 'store/actions'

// import bootstrap components
import { Container, Row, Col, Alert } from "reactstrap"

// import custom common components
import Breadcrumbs from 'components/common/Breadcrumbs'
import Loader from 'components/common/Loader'

// import custom components
import SearchOrderForm from './SearchOrderForm'

// import orders custom components
import OrderDetails from 'pages/orders/OrderDetails'
import OrderRefund from 'pages/orders/OrderRefund'
import OrderList from 'pages/orders/OrderList'
import OrderPaid from 'pages/orders/OrderPaid'

// import constants
import { APP_NAME, ORDERS_PAYBACK, ORDERS_RESEARCH, ORDERS_SHOW, ORDERS_PAYED, USER_SUPERVISOR, USER_KEY } from 'helpers/constants'

// import utilities
import { convertDatesOnTimestamps, formatPhoneNumberForRequest } from 'helpers/utilities'


class SearchOrders extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            query: {
                start: moment(),
                end: moment(),
            },
            detailsVisible: false,
            refundVisible: false,
            payedVisible: false,
            userRole: "",
        }
    }

    componentDidMount() {
        try {
            //Extract user data
            let { role } = JSON.parse(localStorage.getItem(USER_KEY))
            
            //Save user name & role
            this.setState({ userRole: role })
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {// Check state updates
            // console.log(this.props, "SEARCH ORDERS UPDATED")
        } catch (error) {
            console.log(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            this.props.onResetOrders(true)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Toggle modal.
     * 
     * @param {*} option 
     */
    onToggled = (option = "detailsVisible", visible = false) => {
        this.setState({ [option]: visible })
    }

    /**
     * On searched.
     * 
     * @param {*} option 
     */
    onRetrieved = () => {
        try {
            // Get state values
            let { code } = this.state.query

            // Handle search
            if (code && (code !== "")) {
                // Get props values
                let { onSearchOrders, onResetOrders } = this.props

                // Reset values
                onResetOrders(false)

                // Launch search
                onSearchOrders(code, ORDERS_RESEARCH)
            }
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On changed.
     * 
     * @param {*} event 
     */
    onChanged = (event) => {
        try {
            // Get target
            let { checked, value, name, type } = event.target

            // Save values
            this.setState({
                query: {
                    ...this.state.query,
                    [name]: ((type === "checkbox") ? checked : value)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
    * On submited.
    * 
    * @param {*} e 
    * @param {*} values 
    */
    onSubmited = (e, values) => {
        e.preventDefault();//Prevent default behaviour

        try {//Start treatment

            //Get state values
            let { start, end, partner, withPeriod } = this.state.query

            //Get props values
            let { onSearchOrders, onResetOrders } = this.props

            // Reset values
            onResetOrders(false)

            // Init search data
            let data = {
                customer_phone: formatPhoneNumberForRequest(values.phone.trim()),
                partner: (partner ? partner : false)
            }

            // Get converted dates and update search data
            if (withPeriod) {
                let timestamps = convertDatesOnTimestamps(start, end)
                data["start_date"] = timestamps.start
                data["end_date"] = timestamps.end
            }

            // Launch search
            onSearchOrders(data, ORDERS_RESEARCH)
        } catch (error) {
            console.log(error)
        }
    }

    onRefunded = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ option: ORDERS_PAYBACK })

            //Show modal
            this.onToggled("refundVisible", true);
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On date ranged.
     * 
     * @param {*} start 
     * @param {*} end 
     */
    onRanged = (start, end) => {
        try {
            //Save values
            this.setState({
                query: {
                    ...this.state.query,
                    start: start, end: end
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On searched.
     *  
     * @param {*} list 
     * @returns 
     */
    onSearched = (searchedList) => {
        try {
            //Get props
            let { filter, onSetOrders, onFilterOrders } = this.props

            //Reset filters
            if (filter && filter.key) onFilterOrders(filter, true)

            //Search orders
            onSetOrders({ filtereds: searchedList })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On showed.
     * 
     * @param {*} selected 
     */
    onShowed = (selected) => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ order: { _id: selected }, option: ORDERS_SHOW })

            //Show modal
            this.onToggled("detailsVisible", true);
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On filtered.
     * 
     * @param {*} selected 
     */
    onFiltered = (selected) => {
        try {
            //Get props
            let { filter, onFilterOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Save filter
            onFilterOrders(selected, (selected.key === filter.key))
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * on paid.
     * Show set order paid modal form.
     * 
     */
    onPayed = () => {
        try {
            //Get props
            let { onSetOrders, onResetOrders } = this.props

            //Reset state
            onResetOrders(false)

            //Init state
            onSetOrders({ option: ORDERS_PAYED })

            //Show modal
            this.onToggled("payedVisible", true);
        } catch (error) {
            console.log(error)
        }
    }

    render() {
        const { t, alls, orders, order, filter, loading, success, error, option } = this.props
        const { detailsVisible, refundVisible, payedVisible, query, userRole } = this.state

        return (
            <React.Fragment>
                <Helmet>
                    <title>{APP_NAME} | {t("Rechercher une course")}</title>
                </Helmet>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title={t("Rechercher une course")} />

                        <Row className="mb-2">
                            <Col xl="3" md="3" sm="12" className="pe-3 pe-xxl-4">
                                <SearchOrderForm
                                    onSearched={this.onRetrieved}
                                    onChanged={this.onChanged}
                                    onRanged={this.onRanged}
                                    onSubmited={this.onSubmited}
                                    query={query}
                                />
                            </Col>

                            <Col xl="9" md="9" sm="12" className="ps-3 ps-xxl-4">
                                <Row>
                                    {((error != "") && [ORDERS_RESEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Alert color="danger" className="m-0">
                                                {error}
                                            </Alert>
                                        </Col>
                                    ) : (loading && [ORDERS_RESEARCH].includes(option)) ? (
                                        <Col xl="12">
                                            <Loader color="primary" />
                                        </Col>
                                    ) : (
                                        <OrderList
                                            alls={alls}
                                            orders={orders}
                                            filter={filter}
                                            onShowed={this.onShowed}
                                            onSearched={this.onSearched}
                                            onFiltered={this.onFiltered}
                                            showRefresh={false}
                                            listClasses="orders-searches"
                                            filterUnpayed={userRole === USER_SUPERVISOR.key}
                                        />
                                    )}
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                {(detailsVisible) ? (
                    <OrderDetails
                        opened={detailsVisible}
                        onClosed={this.onToggled}
                        onRefunded={this.onRefunded}
                        onPayed={this.onPayed}
                    />
                ) : null}
                {(refundVisible) ? (
                    <OrderRefund
                        opened={refundVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
                {(payedVisible) ? (
                    <OrderPaid
                        opened={payedVisible}
                        onClosed={this.onToggled}
                    />
                ) : null}
            </React.Fragment>
        )
    }

}
SearchOrders.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    orders: PropTypes.array,
    order: PropTypes.object,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
    onShowOrder: PropTypes.func
}
const mapStateToProps = state => ({
    alls: state.commandes.orders,
    orders: state.commandes.filtereds,
    order: state.commandes.order,
    filter: state.commandes.filter,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading
})
const mapDispatchToProps = dispatch => ({
    onFilterOrders: (filter, reset) => dispatch(filterOrders(filter, reset)),
    onSearchOrders: (data, option) => dispatch(searchOrders(data, option)),
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all))
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(SearchOrders)))
