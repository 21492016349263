import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Alert, Col, Row } from 'reactstrap'

// import router link
import { Link } from 'react-router-dom'

// import constants
import { ORDERS_ADD } from 'helpers/constants'

// import custom common components
import Loader from 'components/common/Loader'

// import utilities
import { formatPhoneNumberForDisplay } from 'helpers/utilities'
import PayButton from 'components/common/PayButton'


const SaveTab = React.memo(({ order, loading, option, error, success, onRestarted, onPayed }) => {

    const { customer, customer_name, customer_type, customer_contact, code, delivery_auth, cost, payment } = order

    return (
        <React.Fragment>
            <div style={{ minHeight: '350px' }}>
                {(loading && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <Loader color="primary" />
                            <center>
                                <p className="m-0 p-2">
                                    Enregistrement de la course. Veuillez patienter...
                                </p>
                            </center>
                        </Col>
                    </Row>
                ) : ((error !== "") && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <Alert color="danger" className="m-0 d-flex align-items-center justify-content-between">
                                {error}{" "}
                                <Link 
                                    to="#" 
                                    className="alert-link" 
                                    onClick={() => onPayed({transactionId: cost.transaction_id})}
                                >
                                    Réessayer
                                </Link>
                            </Alert>
                        </Col>
                    </Row>
                ) : (success && (option === ORDERS_ADD)) ? (
                    <Row>
                        <Col xl="12">
                            <div className="text-center">
                                <div className="avatar-md mx-auto">
                                    <div className="avatar-title rounded-circle bg-success bg-soft">
                                        <i className="bx bx-check-double h1 mb-0 text-success" />
                                    </div>
                                </div>

                                <div className="p-2 mt-4">
                                    <h3>
                                        <small className="d-block text-muted fs-14 mt-1">
                                            Une course a été enregistrée {payment ? "et payée" : ""} pour le client 
                                            {" "} <strong>{customer_name}</strong>
                                        </small>
                                        <small className="d-block text-muted fs-12 mt-2">
                                            Un sms de notification sera envoyé au client sur le numéro {" "}
                                            <strong>{formatPhoneNumberForDisplay(customer_contact)}</strong>.
                                        </small>
                                    </h3>
                                </div>

                                {!payment && (
                                    <div className="mt-2">
                                        <p>Procéder au paiement de la course enregistrée</p>
                                        <PayButton
                                            amount={cost.total}
                                            orderCode={code}
                                            onPayed={onPayed}
                                            label="Payer et Continuer"
                                            classes="btn btn-accent"
                                        />
                                    </div>
                                )}

                                {payment && (
                                    <div className="d-flex align-items-center justify-content-center mt-2">
                                        <Link to="/agency-orders" className="btn btn-primary me-1">
                                            Liste des courses
                                        </Link>
                                        <Link
                                            to="#" className="btn btn-outline-primary ms-1"
                                            onClick={() => onRestarted()}
                                        >
                                            Nouvelle course
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </Col>
                    </Row>
                ) : null}
            </div>
        </React.Fragment>
    );
    
});
SaveTab.propTypes = {
    order: PropTypes.object,
}
SaveTab.defaultProps = {
    order: {},
}
export default SaveTab