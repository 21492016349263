// import axios
import axios from 'axios';

// import constants
import { USER_KEY } from 'helpers/constants';

// save bases urls
export const ACCOUNT_BASE_URL = (`${process.env.REACT_APP_API_ACCOUNT_BASE_URL}/`);
export const ORDER_BASE_URL = (`${process.env.REACT_APP_API_ORDER_BASE_URL}/`);

// init axios client
const client = axios.create({
	headers: { Accept: 'application/json', 'Content-Type': 'application/json' }
})

const NO_TOKEN_URLS = ["users/login", "orders/create"]

// config request
client.interceptors.request.use(function (config) {
	if (!NO_TOKEN_URLS.includes(config?.url?.split('/')?.slice(-2)?.join('/'))) {
		config.headers = {
			...config.headers, token: extractToken()
		}
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});

// config response
client.interceptors.response.use(function (response) {
	return {
		data: response.data.data,
		status: response.data.status,
		statusText: response.data.message
	}
}, function (error) {
	return Promise.reject(error);
});

// extract token
const extractToken = () => {
	try {
		return JSON.parse(localStorage.getItem(USER_KEY)).token
	} catch (error) {
		console.log(error)
		return ""
	}
}

export default client