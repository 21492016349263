import React from 'react'
import PropTypes from 'prop-types'

// import components
import { Alert, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"

// import store and actions
import { connect } from 'react-redux'
import { resetOrders, setOrders, showOrder } from 'store/actions'

// import router
import { Link, withRouter } from "react-router-dom"

// import translation
import { withTranslation } from "react-i18next"

// import scroll bar
import SimpleBar from "simplebar-react"

// import deep-equal
import equal from "deep-equal"

// import moment
import moment from 'moment'
import 'moment/locale/fr'

// import custom common components
import Loader from 'components/common/Loader'

// import constants
import {
    EVENTS_FORWARD, EVENTS_INCIDENT, EVENTS_RTS, EVENTS_TREATMENT, ORDERS_COMPLETED,
    ORDERS_SHOW, ORDERS_STARTED, USER_AGENCY, USER_BILLER, USER_FACTOR, USER_KEY, USER_SUPERVISOR
} from 'helpers/constants'

// import utilities
import {
    formatEventsWithType, formatIssuesWithGravity, getConstantObject,
    formatPhoneNumberForDisplay, formatUserWithName
} from 'helpers/utilities'


class OrderDetails extends React.Component {

    constructor(props) {
        super(props)

        this.refDiv = React.createRef()

        this.state = {
            userRole: ""
        }
    }

    componentDidMount() {
        try {
            // Extract and save user role
            if (localStorage.getItem(USER_KEY)) {
                this.setState({
                    userRole: JSON.parse(localStorage.getItem(USER_KEY)).role
                })
            }

            //Load order
            this.onLoaded()
        } catch (error) {
            console.log(error)
        }
    }

    componentDidUpdate(prevProps) {
        try {//Check state updates
            if (!equal(prevProps, this.props)) {
                let { order, onClosed, onRefreshed } = this.props
                if (order.transfert && order.transfert.forwarded) {
                    let { userRole } = this.state
                    if (userRole === USER_AGENCY.key) {
                        //Refresh list
                        if (onRefreshed) onRefreshed()
                        // Close modal
                        onClosed("detailsVisible", false)
                    } else {
                        //Refresh modal
                        this.onLoaded()
                    }
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    componentWillUnmount() {
        try {// Reset state
            let { onSetOrders, onResetOrders } = this.props
            onResetOrders(false)
            onSetOrders({ order: {} })
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * On loaded.
     * 
     */
    onLoaded = () => {
        try {
            //Get props
            let { order, onShowOrder } = this.props

            //Load order details
            onShowOrder(order._id, ORDERS_SHOW)
        } catch (error) {
            console.log(error)
        }
    }

    /**
     * Calculate current status duration.
     * 
     * @returns string
     */
    calculateCurrentStatusDuration() {
        try {
            let { events, current_status } = this.props.order
            let current_status_event = events.filter((item) => (item.event === EVENTS_TREATMENT.key) && (item.status === current_status))
            return ((current_status_event[0] && current_status_event[0].at) ? moment.unix(current_status_event[0].at).fromNow() : "")
        } catch (error) {
            console.log(error)
            return ""
        }
    }


    render() {
        const { userRole } = this.state
        const { opened, modalClass, modalFullscreen, modalSize, option, error, loading, order, onClosed, onFinished, onTransferred, onAssigned, onRefunded, onPayed } = this.props
        const {
            code, start_point, delivery_point, created_at, completed_at, received_at, delivery_auth, nature, description_file, description_text,
            refund, payment, current_status, reported, incidents, events, current_user, current_user_id, customer_id, customer, receiver, round_trip
        } = order

        let order_status = (order && order.current_status) ? getConstantObject(order.current_status) : {}
        let order_nature = (order && order.nature) ? getConstantObject(order.nature) : {}
        let order_history = (order && order.events) ? formatEventsWithType(order.events) : []
        let order_issues = (order && order.incidents) ? formatIssuesWithGravity(order.incidents) : []
        let order_agent = (order && order.current_user && order.current_user._id) ? formatUserWithName(order.current_user) : {}
        let order_vehicle = (order && order.current_user && current_user.vehicle_type) ? getConstantObject(order.current_user.vehicle_type) : {}

        let order_refunded = (refund && refund.status && refund.at && (refund.at !== 0) && refund.reason)
        let order_completed = (current_status && (current_status === ORDERS_COMPLETED.key) && completed_at && (completed_at !== 0))

        return (
            <React.Fragment>
                <Modal
                    isOpen={opened} centered={true}
                    className={`order-card ${modalClass}${modalFullscreen ? " modal-fullscreen" : ""}`}
                    size={`${modalFullscreen ? "xl" : modalSize}`}
                >
                    <ModalHeader tag="h4" className="order-header">
                        <span className="order-title">
                            Détails d'une course
                            {(order && order._id && order.code) ? (
                                <span className="d-block fs-12 text-secondary">
                                    Course {" "}
                                    <strong> #{code} </strong>
                                    du {" "}
                                    <strong> {`${moment.unix(created_at).format('DD MMM YYYY')}`} </strong>
                                    {order_refunded ? (
                                        <span className="d-block fw-bold text-danger">
                                            Remboursée
                                        </span>
                                    ) : null}
                                </span>
                            ) : null}
                        </span>
                        {(loading && (option === ORDERS_SHOW)) ? null : (
                            <span className="toolbar button-items">
                                <button
                                    type="button"
                                    className="btn btn-outline-light btn-sm mx-1 center-ibox"
                                    onClick={() => this.onLoaded()}
                                >
                                    Actualiser
                                    <i className="bx bx-reset ms-1" />
                                </button>
                                {((userRole === USER_AGENCY.key) && (current_status === ORDERS_STARTED.key) && onFinished) ? (
                                    <>
                                        {(order_completed || order_refunded) ? null : (
                                            <button
                                                type="button"
                                                className="btn btn-outline-success btn-sm mx-1 center-ibox"
                                                onClick={() => onFinished()}
                                            >
                                                Terminer
                                                <i className="bx bxs-hourglass-bottom ms-1" />
                                            </button>
                                        )}
                                    </>
                                ) : null}
                                {((current_user_id !== "") && onTransferred) ? (
                                    <>
                                        {(order_completed || order_refunded) ? null : (
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-sm mx-1 center-ibox"
                                                onClick={() => onTransferred()}
                                            >
                                                Transférer
                                                <i className="bx bx-fast-forward ms-1" />
                                            </button>
                                        )}
                                    </>
                                ) : ((userRole === USER_SUPERVISOR.key) && onAssigned) ? (
                                    <>
                                        {(order_completed || order_refunded) ? null : (
                                            <button
                                                type="button"
                                                className="btn btn-outline-primary btn-sm mx-1 center-ibox"
                                                onClick={() => onAssigned()}
                                            >
                                                Assigner
                                                <i className="bx bx-user ms-1" />
                                            </button>
                                        )}
                                    </>
                                ) : null}
                                {((userRole === USER_SUPERVISOR.key) && (current_status === "") && onPayed) ? (
                                    <button
                                        type="button"
                                        className="btn btn-outline-danger btn-sm mx-1 center-ibox"
                                        onClick={() => onPayed()}
                                    >
                                        Ajouter un paiement
                                        <i className="bx bx-money ms-1" />
                                    </button>
                                ) : null}
                                {((userRole === USER_BILLER.key) && (customer_id === "") && onRefunded) ? (
                                    <>
                                        {order_refunded ? null : (
                                            <button
                                                type="button"
                                                className="btn btn-outline-danger btn-sm mx-1 center-ibox"
                                                onClick={() => onRefunded()}
                                            >
                                                Rembourser
                                                <i className="bx bx-money ms-1" />
                                            </button>
                                        )}
                                    </>
                                ) : null}
                            </span>
                        )}
                    </ModalHeader>
                    <ModalBody>
                        <Row className="m-1 g-1">
                            <Col xl="12">
                                {(loading && (option === ORDERS_SHOW)) ? (
                                    <Loader color="primary" />
                                ) : ((error !== "") && (option === ORDERS_SHOW)) ? (
                                    <Alert color="danger" className="d-flex align-items-center justify-content-between mb-0">
                                        {error}{" "}
                                        <Link to="#" className="alert-link" onClick={() => this.onLoaded()}>
                                            Réessayer
                                        </Link>
                                    </Alert>
                                ) : (order && order._id && order.code) ? (
                                    <Row className="order-details g-2">
                                        <Col xl="3" sm="12" className="my-1 my-lg-0">
                                            <div className="border border-light rounded">
                                                <SimpleBar style={{ maxHeight: "500px" }} ref={this.refDiv}>
                                                    <div className="p-3">
                                                        {(order_history && (order_history.length > 0)) ? (
                                                            <ul className="verti-timeline list-unstyled m-0">
                                                                {order_history.map((item, index) => (
                                                                    <li key={`order_event_${index}`} className="event-list">
                                                                        <div className="event-timeline-dot">
                                                                            {((item.event === EVENTS_TREATMENT.key) && (item.status === ORDERS_COMPLETED.key)) ? (
                                                                                <i className="bx bxs-check-circle fs-18 text-success" />
                                                                            ) : (
                                                                                <i className={`bx bxs-${item.type.icon}-circle fs-18 text-${item.type.color}`} />
                                                                            )}
                                                                        </div>
                                                                        <span className="d-block fs-13 text-dark">
                                                                            {(item.status) ? getConstantObject(item.status, "name") :
                                                                                (item.event === EVENTS_INCIDENT.key) ? "Incident signalé" :
                                                                                    (item.event === EVENTS_RTS.key) ? "Course en RTS" :
                                                                                        (item.event === EVENTS_FORWARD.key) ? "Course transferée" : ""}
                                                                        </span>
                                                                        {item.user ? (
                                                                            <span className="d-block fs-12 text-muted orders-icons">
                                                                                <small className="fst-light me-1">
                                                                                    {(item.event === EVENTS_FORWARD.key) ? "à" : "par"}
                                                                                </small>
                                                                                {item.user.name ? item.user.name : (`${item.user.firstname} ${item.user.lastname}`)}
                                                                                <span
                                                                                    className="ic-event ms-1"
                                                                                    title={`${(item.user.role === USER_AGENCY.key) ? "Agence" : "Facteur"}`}
                                                                                >
                                                                                    <i className={`bx bxs-${(item.user.role === USER_AGENCY.key) ? "store" : "user"}`} />
                                                                                </span>
                                                                            </span>
                                                                        ) : null}
                                                                        <span className="d-block fs-11 text-muted">
                                                                            {moment.unix(item.at).format("DD MMM YYYY à HH:mm")}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        ) : (
                                                            <p className="m-0 p-2">
                                                                Aucun suivi à afficher
                                                            </p>
                                                        )}
                                                    </div>
                                                </SimpleBar>
                                            </div>
                                        </Col>

                                        <Col xl="6" sm="12" className="my-1 my-lg-0">
                                            <Row>
                                                <Col sm="12">
                                                    {(description_file !== "") ? (
                                                        <audio controls="controls" preload="metadata" className="m-0 w-100 rounded">
                                                            <source src={description_file} type="audio/mpeg" />
                                                            Votre navigateur ne prend pas en charge l'élément <code>audio</code>.
                                                        </audio>
                                                    ) : (description_text !== "") ? (
                                                        <div className="orders-points rounded bg-light p-3 mb-2">
                                                            <span className="d-flex align-items-center mb-1">
                                                                <span className="points-box description-point me-1">
                                                                    <i className="bx bxs-map text-muted" />
                                                                </span>
                                                                <small className="text-muted fw-bolder">Description</small>
                                                            </span>
                                                            <p className="m-0 p-0">
                                                                {description_text}
                                                            </p>
                                                        </div>
                                                    ) : null}
                                                    <div className="bg-section rounded p-3">
                                                        <Row className="g-0">
                                                            <Col xl="6" sm="12" className="my-1 my-lg-0">
                                                                <div className="orders-points pe-1">
                                                                    <span className="d-flex align-items-center mb-1">
                                                                        <span className="points-box start-point me-1">
                                                                            <i className="bx bxs-map text-orange" />
                                                                        </span>
                                                                        <small className="text-muted fw-bolder">Enlèvement</small>
                                                                    </span>
                                                                    <span className="d-block fs-12 fw-bolder">
                                                                        {formatPhoneNumberForDisplay(start_point.contact)}
                                                                    </span>
                                                                    <span className="d-block fs-13 text-dark">
                                                                        {start_point.address}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col xl="6" sm="12" className="my-1 my-lg-0">
                                                                <div className="orders-points ps-1">
                                                                    <span className="d-flex align-items-center mb-1">
                                                                        <span className="points-box end-point me-1">
                                                                            <i className="bx bxs-map text-blue" />
                                                                        </span>
                                                                        <small className="text-muted fw-bolder">Livraison</small>
                                                                    </span>
                                                                    <span className="d-block fs-12 fw-bolder">
                                                                        {formatPhoneNumberForDisplay(delivery_point.contact)}
                                                                    </span>
                                                                    <span className="d-block fs-13 text-dark">
                                                                        {delivery_point.address}
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>

                                                <Col sm="12" className="my-2">
                                                    {(reported && incidents && (incidents.length > 0)) ? (
                                                        <div className="border border-danger rounded p-3">
                                                            <span className="text-muted mb-1 mb-lg-0">
                                                                Les incidents({incidents.length})
                                                            </span>

                                                            <ul className="orders-issues">
                                                                {order_issues.map((item, index) => (
                                                                    <li
                                                                        key={`order_issue_${index}`}
                                                                        className={`issues-${item.gravity.color} rounded p-2`}
                                                                    >
                                                                        <span className="d-block fs-13 text-dark">
                                                                            {item.name}
                                                                        </span>
                                                                        <span className="d-block fs-11 text-dark">
                                                                            {moment.unix(item.created_at).format("DD MMM YYYY à HH:mm")}
                                                                        </span>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    ) : (
                                                        <div className="border border-light rounded p-3">
                                                            <p className="m-0">
                                                                Aucun incident n'a été signalé sur cette course
                                                            </p>
                                                        </div>
                                                    )}
                                                </Col>

                                                {order_refunded ? (
                                                    <Col sm="12">
                                                        <div className="border border-danger rounded p-3">
                                                            Course remboursée le {" "}
                                                            <strong> {`${moment.unix(refund.at).format('DD MMM YYYY')}`} </strong> {" "}
                                                            à {" "} <strong> {`${moment.unix(refund.at).format('HH:mm:ss')}`} </strong>
                                                            <span className="d-block mt-1">
                                                                <small className="d-block text-muted">au motif de</small>
                                                                {refund.reason}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </Col>

                                        <Col xl="3" sm="12" className="my-1 my-lg-0">
                                            <Row>
                                                <Col sm="12">
                                                    <div className="bg-section rounded p-3">
                                                        <span>
                                                            Course <span className="fw-bold text-primary">#{code}</span>
                                                        </span> {" "}
                                                        <span className="d-block">
                                                            du {" "}
                                                            <span className="fw-bold">
                                                                {moment.unix(created_at).format("DD MMM YYYY")}
                                                            </span> à {" "}
                                                            <span className="fw-bold">
                                                                {moment.unix(created_at).format("HH:mm")}
                                                            </span>
                                                        </span>
                                                        <span className="d-block fw-bolder text-dark mt-1 fs-20">
                                                            {payment.price}<small>(XOF)</small>
                                                        </span>
                                                    </div>
                                                </Col>

                                                {current_status && (
                                                    <Col sm="12" className="mt-2">
                                                        <div className={`bg-soft bg-${order_status.color} rounded p-3`}>
                                                            <span className={`d-block fw-bolder fs-15 text-${order_status.color}`}>
                                                                {order_status.name}
                                                            </span>
                                                            <small className="d-block fw-bold text-muted fst-italic">
                                                                {this.calculateCurrentStatusDuration()}
                                                            </small>
                                                        </div>
                                                    </Col>
                                                )}

                                                <Col sm="12" className="mt-2">
                                                    <div className={`bg-soft bg-${order_nature.color} rounded p-3`}>
                                                        <span className={`d-flex align-items-center justify-content-between fs-14 fw-bold text-${order_nature.color}`}>
                                                            {order_nature.name}
                                                            <i className={`bx ${order_nature.icon} bx-xs text-${order_nature.color}`} />
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col sm="12" className="mt-2">
                                                    {(delivery_auth && delivery_auth.enabled) ? (
                                                        <div className="bg-soft bg-blue rounded p-3">
                                                            <span className="text-blue d-flex align-items-center justify-content-between">
                                                                Double confirmation demandée
                                                                <i className='bx bx-check-double bx-xs text-blue' />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="bg-soft bg-secondary rounded p-3">
                                                            <span className="text-muted d-flex align-items-center justify-content-between">
                                                                Pas de double confirmation
                                                                <i className='bx bx-check-double bx-xs text-muted' />
                                                            </span>
                                                        </div>
                                                    )}
                                                </Col>

                                                <Col sm="12" className="mt-2">
                                                    {(round_trip) ? (
                                                        <div className="bg-soft bg-blue rounded p-3">
                                                            <span className="text-blue d-flex align-items-center justify-content-between">
                                                                Aller-retour
                                                                <i className='bx bx-transfer bx-xs text-blue' />
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div className="bg-soft bg-secondary rounded p-3">
                                                            <span className="text-muted d-flex align-items-center justify-content-between">
                                                                Pas d'aller-retour
                                                                <i className='bx bx-transfer bx-xs text-muted' />
                                                            </span>
                                                        </div>
                                                    )}
                                                </Col>

                                                <Col sm="12" className="mt-2">
                                                    <div className="bg-section rounded p-3">
                                                        <address className="mb-0">
                                                            <small> Donneur d'ordre </small>
                                                            <span className="d-block fs-13 fw-bold mt-1">
                                                                {formatPhoneNumberForDisplay(customer.phone)}
                                                            </span>
                                                            <span className="d-block text-dark fs-14 fw-bolder">
                                                                {customer.name}
                                                            </span>
                                                            <small className="d-block fs-11 fst-italic">
                                                                {customer.partner ? "Contractuel" : "Occasionnel"}
                                                            </small>
                                                        </address>
                                                    </div>
                                                </Col>

                                                {(receiver && receiver.name && (receiver.name !== "")) ? (
                                                    <Col sm="12" className="mt-2">
                                                        <div className="bg-section rounded p-3">
                                                            <div className="d-flex align-items-center justify-content-between orders-thumbnails">
                                                                <address className="mb-0">
                                                                    <small> Destinataire </small>
                                                                    <span className="d-block fs-13 fw-bold mt-1">
                                                                        {formatPhoneNumberForDisplay(receiver.phone)}
                                                                    </span>
                                                                    <span className="d-block text-dark fs-14 fw-bolder">
                                                                        {receiver.name}
                                                                    </span>
                                                                </address>
                                                                {(order_completed && receiver.signature && (receiver.signature !== "")) ? (
                                                                    <img
                                                                        alt={`Accusé de réception, course #${code}`}
                                                                        className="signature-thumbnail"
                                                                        src={receiver.signature}
                                                                        title="Signature du destinataire"
                                                                    />
                                                                ) : (order_completed && receiver.in_agency) ? (
                                                                    <span className="agency-thumbnail" title="Récupérée en agence">
                                                                        <i className={`bx bxs-store text-${order_status.color}`} />
                                                                    </span>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                ) : null}

                                                {(order_agent && order_agent._id && (userRole === USER_SUPERVISOR.key) && (!order_completed)) ? (
                                                    <Col sm="12" className="mt-2">
                                                        <div className="bg-section rounded p-3">
                                                            <address className="mb-0">
                                                                <small>
                                                                    {(order_agent.role === USER_AGENCY.key) ? "Agence en charge" :
                                                                        (order_agent.role === USER_FACTOR.key) ? "Facteur en charge" :
                                                                            "Utilisateur en charge"}
                                                                </small>
                                                                <span className="d-block fs-13 fw-bold mt-1">
                                                                    {formatPhoneNumberForDisplay(order_agent.phone)}
                                                                </span>
                                                                <span className="d-block text-dark fs-14 fw-bolder">
                                                                    {`${order_agent.name}`}
                                                                </span>
                                                                <small className="d-block fs-11 fst-italic">
                                                                    {(order_vehicle && order_vehicle.name) ? (
                                                                        <>
                                                                            {order_vehicle.name} {" "} {" "}
                                                                            <i className={`${order_vehicle.icon} text-muted`} />
                                                                        </>
                                                                    ) : (order_agent.role && (order_agent.role === USER_AGENCY.key)) ? (
                                                                        <>
                                                                            {(order_agent.contact_point) ? "Point contact" : "Point relais"}
                                                                        </>
                                                                    ) : null}
                                                                </small>
                                                            </address>
                                                        </div>
                                                    </Col>
                                                ) : null}
                                            </Row>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Alert color="info"> Aucune course retrouvée </Alert>
                                )}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <button
                            type="button" data-dismiss="modal" className="btn btn-light"
                            onClick={() => onClosed("detailsVisible", false)}
                        >
                            Fermer
                        </button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }

}
OrderDetails.propTypes = {
    t: PropTypes.any,
    history: PropTypes.any,
    opened: PropTypes.bool,
    error: PropTypes.string,
    option: PropTypes.string,
    loading: PropTypes.bool,
    success: PropTypes.bool,
    order: PropTypes.object,
    onClosed: PropTypes.func,
    onPayed: PropTypes.func,
    onTransferred: PropTypes.func,
    onAssigned: PropTypes.func,
    onSetOrders: PropTypes.func,
    onResetOrders: PropTypes.func,
    onShowOrder: PropTypes.func,
}
OrderDetails.defaultProps = {
    opened: false,
    modalClass: "order-details-modal",
    modalFullscreen: false,
    modalSize: "xl"
}
const mapStateToProps = state => ({
    order: state.commandes.order,
    error: state.commandes.error,
    option: state.commandes.option,
    success: state.commandes.success,
    loading: state.commandes.loading,
})
const mapDispatchToProps = dispatch => ({
    onSetOrders: (data) => dispatch(setOrders(data)),
    onResetOrders: (all) => dispatch(resetOrders(all)),
    onShowOrder: (id, option) => dispatch(showOrder(id, option)),
})
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(OrderDetails)))
